<template>
  <div>
    <div
      v-if="tab === 1"
      class="login d-flex flex-column justify-content-around"
    >
      <div class="message-logo d-flex justify-content-center">
        <div>
          <div class="box-right">CONFIRMEZ VOTRE NUMÉRO DE TÉLÉPHONE</div>
        </div>
        <div><img src="../assets/img/logo-verify.svg" alt="" /></div>
      </div>
      <div class="get-code align-items-center d-flex flex-column">
        <div class="d-flex justify-content-center align-items-center">
          <vue-phone-number-input
            v-model="localPhone"
            default-country-code="FR"
            :preferred-countries="['FR', 'AM']"
            size="lg"
            width="100"
            :no-example="true"
            v-on:keyup.enter="registerForm"
            @update="onPhoneUpdate"
          />
        </div>
        <button @click="registerForm" class="main-button" :disabled="loading">
          Get Code
        </button>
      </div>
    </div>
    <div
      v-if="tab === 2"
      class="login d-flex flex-column justify-content-around"
    >
      <div class="message-logo d-flex justify-content-center">
        <div>
          <div class="box-right">ENTREZ VOTRE CODE DE VÉRIFICATION</div>
        </div>
        <div><img src="../assets/img/logo-verify.svg" alt="" /></div>
      </div>
      <div class="get-code align-items-center d-flex flex-column">
        <div
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            align-items-center
          "
        >
          <input
            placeholder="____"
            v-model="verificationCode"
            class="number-input sms-code"
            type="text"
            pattern="\d*"
            maxlength="4"
          />
          <Transition v-if="error" name="bounce">
            <span class="error">Code de vérification invalide</span>
          </Transition>
        </div>
        <button @click="registerForm" class="main-button">Confirmer</button>
      </div>
    </div>
    <div
      v-if="tab === 3"
      class="login d-flex flex-column justify-content-around"
    >
      <div class="message-logo d-flex justify-content-center">
        <div>
          <div class="box-right">ENTREZ VOTRE CODE DE VÉRIFICATION</div>
        </div>
        <div><img src="../assets/img/logo-verify.svg" alt="" /></div>
      </div>
      <div class="get-code align-items-center d-flex flex-column">
        <div
          class="
            d-flex
            register-section
            flex-column
            justify-content-center
            align-items-center
          "
        >
          <input
            placeholder="VOTRE E-MAIL..."
            v-model="email"
            class="mail-input"
            type="email"
          />
          <input
            placeholder="YOUR NAME..."
            v-model="name"
            class="mail-input"
            type="text"
          />
        </div>
        <button @click="registerForm" class="main-button">S'inscrire</button>
      </div>
    </div>
  </div>
</template>
<script>
import codes from "@/assets/phone-codes.json";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      tab: 1,
      firstText: "Commencez",
      SecondText: "Rejoindre",
      localPhone: null,
      phoneNumber: null,
      verificationCode: null,
      sid: null,
      name: "",
      email: "",
      error: null,
      loading: false,
      countryCode: "+33",
      codes: [],
    };
  },
  created() {
    this.$store.commit("SET_DEVICE_REGISTRATION", false);

    this.codes = codes.map((c) => {
      return {
        value: c.dial_code,
        text: c.dial_code,
      };
    });
  },
  methods: {
    registerForm() {
      const phoneValidation = /^\+?[1-9][0-9]{7,14}$/;
      if (this.tab === 1 && phoneValidation.test(+this.phoneNumber)) {
        this.loading = true;
        // e.preventDefault();
        // debugger;
        this.$http.auth
          .sendVerification(this.phoneNumber)
          .then((res) => {
            this.sid = res.data.sid;
            this.tab++;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
      if (this.tab == 2) {
        const data = {
          sid: this.sid,
          phone: this.phoneNumber,
          code: this.verificationCode,
        };
        this.$http.auth
          .verify(data)
          .then((res) => {
            this.$store.commit("USER_LOGIN", res.data);
            if (!this.$store.state.user.name) {
              this.tab++;
            } else {
              this.$router.push("/profile");
            }
          })
          .catch((err) => {
            console.log(err);
            this.error = err;
          });
      }
      if (this.tab == 3) {
        const userData = {
          avatarId: 0,
          name: this.name,
          email: this.email.toLowerCase(),
        };
        this.$http.auth
          .register(userData, this.$store.getters.user.id)
          .then((res) => {
            this.$store.commit("UPDATE_USER", res.data);
            this.$router.push("/profile");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onPhoneUpdate(e) {
      this.phoneNumber = e.formattedNumber;
    },
  },
};
</script>
<style scoped lang="scss">
.vue-phone-number-input {
  border: 2px solid #000;
  border-radius: 5px;
  transform: scale(1.145);
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
